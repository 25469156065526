import React, { Fragment, useState, useEffect, useRef } from 'react';
import * as css from '../../styles/HomeBanner.module.css';
import { getClassName, get_CTA_redirection } from '@/common_js/common';
import RightArrow from '../../public/assets/images/blue_right_arrow.png';
import Image from 'next/image';

const Banner = (props) => {
  const { banner_data, isLoading, setisLoading, LoadingComponent, homepage_carousalbanner, homePageCmsData } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [CmsData, setCmsData] = useState(homePageCmsData || {});

  const images = homepage_carousalbanner?.map(item => item.image) || [];
  const intervalRef = useRef(null);

  const startAutoSlide = () => {
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 5000);
  };

  const resetAutoSlide = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    startAutoSlide();
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? prevIndex : prevIndex - 1));
    resetAutoSlide();
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? prevIndex : prevIndex + 1));
    resetAutoSlide();
  };

  useEffect(() => {
    async function fetchData() {
      let data = await getCmsDataBySection("homeDesktopBanner");
      setCmsData(data?.homeDesktopBanner || {});
      setisLoading(false);
    }
    if (!CmsData?.data?.length)
      fetchData();
    else if (isLoading)
      setisLoading(false);
  }, [CmsData, isLoading, setisLoading]);

  useEffect(() => {
    startAutoSlide();

    return () => clearInterval(intervalRef.current);
  }, [images.length]);

  const handleImageClick = (link) => {
    if (link && link !== "#" && link !== "") {
      window.open(link, '_blank');
    }
  };
  const currentLink = CmsData?.data?.[currentIndex]
    ? get_CTA_redirection(CmsData.data[currentIndex].CTA.type, CmsData.data[currentIndex].CTA.data)
    : "#";

  return (
    <Fragment>
      {isLoading ? (
        LoadingComponent
      ) : (
        <div
          style={{ background: banner_data.css_content }}
          className={getClassName(css['ig_bannernewcontainer-wrapper'])}
        >
          <div className={getClassName(css['ig_bannernewcontainer'])}>
            <div className={getClassName(css['ig_bannernewsection'])}>
              <h1 className={getClassName(css['ig_banner_heading'])}>Book Hotels</h1>

              <div className={getClassName(css['carousel_container'])}>
                <div
                  className={getClassName(css['carousel_inner'])}
                  style={{ transform: `translateX(-${currentIndex * 100}%)` }} // Sliding effect
                >
                  {images.map((image, index) => (
                    <img
                      key={index}
                      className={getClassName(css['carousel__image'])}
                      src={image}
                      style={{ borderRadius: "10px", cursor: 'pointer' }}
                      alt={`Slide ${index}`}
                      onClick={() => handleImageClick(currentLink)}
                    />
                  ))}
                </div>
                {/* Navigation Buttons */}
                {images?.length > 1 &&
                  <button
                    className={getClassName(css['carousel_button_left'])}
                    onClick={handlePrevClick}
                    disabled={currentIndex === 0}
                    style={{
                      background: currentIndex === 0 ? 'rgb(255 255 255 / 66%)' : 'rgb(255 255 255)',
                      cursor: currentIndex === 0 ? 'not-allowed' : 'pointer',
                    }}
                  >
                    <span>
                      <Image className={getClassName(css['rotate_180deg'])} src={RightArrow} alt="" />
                    </span>
                  </button>
                }
                {images?.length > 1 &&
                  <button
                    className={getClassName(css['carousel_button_right'])}
                    onClick={handleNextClick}
                    disabled={currentIndex === images.length - 1}
                    style={{
                      background: currentIndex === images.length - 1 ? 'rgb(255 255 255 / 66%)' : 'rgb(255 255 255)',
                      cursor: currentIndex === images.length - 1 ? 'not-allowed' : 'pointer',
                    }}
                  >
                    <span>
                      <Image src={RightArrow} alt="" />
                    </span>
                  </button>
                }


              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Banner;
